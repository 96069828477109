import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Hydro from "/src/components/img/hydro"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"


export default () => (
  <Layout>
    <SEO title="Úvod do hydrologie a hydrogeografie" />
    <h1>Úvod do hydrologie a hydrogeografie</h1>
    <p>Předmětem v&yacute;zkumu <strong>hydrologie</strong> je <strong>hydrosf&eacute;ra</strong>, a to ve v&scaron;ech jej&iacute;ch form&aacute;ch a skupenstv&iacute;ch &ndash; voda, sn&iacute;h, led i vzdu&scaron;n&aacute; vlhkost v&nbsp;atmosf&eacute;ře. Zkoum&aacute; z&aacute;konitosti v&yacute;skytu, oběhu, časov&eacute;ho a prostorov&eacute;ho rozdělen&iacute; vody na Zemi, jej&iacute; vz&aacute;jemn&eacute; působen&iacute; s&nbsp;prostřed&iacute;m včetně živ&eacute; př&iacute;rody, jako i jej&iacute; fyzik&aacute;ln&iacute;, chemick&eacute; a biologick&eacute; vlastnosti.</p>
    <p>Je spjata s&nbsp;živou př&iacute;rodou &ndash; podmiňuje jej&iacute; existenci a jej&iacute; kvalita a množstv&iacute; ovlivňuje habitaty. Stejně mohou působit i různ&eacute; tvary koryt, př&iacute;kladem může b&yacute;t regulace toku v&nbsp;podobě zpevněn&eacute;ho lichoběžn&iacute;kov&eacute;ho koryta. Takov&yacute; z&aacute;sah m&aacute; pak siln&yacute; negativn&iacute; dopad na biodiverzitu &ndash; v takov&eacute;m toce totiž chyb&iacute; &uacute;kryty pro různ&eacute; druhy atp.</p>
    <p>Vědn&iacute; obor <strong>hydrogeografie</strong> se zaob&iacute;r&aacute; vztahy mezi vodn&iacute;mi &uacute;tvary na pevnině a ostatn&iacute;mi krajinotvorn&yacute;mi prvky. Je považov&aacute;na za souč&aacute;st fyzick&eacute; geografie.</p>
    <hr />
    <h2>Členěn&iacute; hydrologie</h2>
    <p>Zde je uvedeno pouze <strong>z&aacute;kladn&iacute; členěn&iacute; podle předmětu v&yacute;zkumu</strong>, nepostihuje tak v&scaron;echny discipl&iacute;ny geografie.</p>
    <ol>
    <li><strong>hydrologie moř&iacute; (oce&aacute;nografie)</strong>
    <ol>
    <li>fyzick&aacute; o. &ndash; fyz. vlastnosti mořsk&eacute; vody, procesy interakc&iacute; atmosf&eacute;ra &ndash; oce&aacute;n, pohyb mořsk&yacute;ch vod</li>
    <li>chemick&aacute; o.</li>
    <li>biologick&aacute; o.</li>
    <li>mořsk&aacute; geologie a geofyzika</li>
    </ol>
    </li>
    <li><strong>hydrologie pevnin</strong>
    <ol>
    <li>atmosf&eacute;ry</li>
    <li>tekouc&iacute;ch vod (potamologie)</li>
    <li>stojat&yacute;ch vod (limnologie)</li>
    <li>mokřadů</li>
    <li>podzemn&iacute;ch vod (hydrogeologie)</li>
    <li>půdy (hydropedologie)</li>
    <li>ledovců (glaciologie)</li>
    <li>sněhu a ledu (kryologie)</li>
    </ol>
    </li>
    </ol>
    <hr />
    <h2>Vybran&eacute; d&iacute;lč&iacute; discipl&iacute;ny</h2>
    <p><strong>Hydrometrie</strong> je č&aacute;st hydrologie zab&yacute;vaj&iacute;c&iacute; se způsoby měřen&iacute; hydrologick&yacute;ch prvků potřebn&yacute;mi př&iacute;stroji, jako i metodami jejich z&iacute;sk&aacute;v&aacute;n&iacute;, přenosu, zpracov&aacute;n&iacute; a archivac&iacute; hydrologick&yacute;ch dat.</p>
    <p><strong>Hydrografie</strong> pak popisuje hydrologick&eacute; procesy, jevy prob&iacute;haj&iacute;c&iacute; ve vodn&iacute;ch &uacute;tvarech a z&aacute;konitosti jejich geografick&eacute;ho rozložen&iacute; na Zemi.</p>
    <hr />
    <h2>Vybran&eacute; samostatn&eacute; discipl&iacute;ny</h2>
    <p><strong>Hydrogeologie </strong>se zab&yacute;v&aacute; původem a tvorbou podzemn&iacute;ch vod, formami jejich v&yacute;skytu, jejich roz&scaron;&iacute;řen&iacute;m, pohybem, režimy, zdroji, vztahy k&nbsp;povrchov&yacute;m vod&aacute;m, př&iacute;rodn&iacute;mi podm&iacute;nkami využitelnosti a regulace. Při tom přihl&iacute;ž&iacute; na chemick&eacute;, fyzick&eacute; a biologick&eacute; vlastnosti a na procesy vz&aacute;jemn&eacute;ho působen&iacute; podzemn&iacute;ch vod s&nbsp;horninami, půdami, plyny a dal&scaron;&iacute;mi tekutinami.</p>
    <p><strong>Hydrometeorologie</strong> je pak discipl&iacute;na zaob&iacute;raj&iacute;c&iacute; se v&yacute;skytem a pohybem vody v&nbsp;ovzdu&scaron;&iacute; jakou souč&aacute;st&iacute; hydrologick&eacute;ho cyklu. Někdy je považov&aacute;na za společn&yacute; z&aacute;klad hydrologie a meteorologie.</p>
    <p><strong>Hydropedologie</strong> se zab&yacute;v&aacute; statikou a pohybem půdn&iacute;ch vod, vlastnostmi vody a roztoků v&nbsp;půdě ve vztahu k&nbsp;vlastnostem půdy jakou souč&aacute;sti syst&eacute;mu podzemn&iacute; voda &ndash; půda &ndash; rostlina &ndash; atmosf&eacute;ra.</p>
    <p>Pozn.: Podpovrchov&aacute; voda se skl&aacute;d&aacute; z&nbsp;půdn&iacute; vody a podzemn&iacute; vody, kter&eacute; jsou od sebe oddělen&yacute; hladinou podzemn&iacute; vody! Pojem spodn&iacute; voda z&nbsp;pohledu hydrologie neexistuje!</p>

    <hr />
    <ImgCon><Hydro /><div>
    <h2>Studijn&iacute; materi&aacute;ly Hydrologie</h2>
    <p>T&eacute;ma <strong>Úvod do hydrologie a hydrogeografie</strong> je souč&aacute;st&iacute; <Link to="/hydrologie/">studijn&iacute;ch materi&aacute;lů Hydrologie a hydrogeografie</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/hydrologie/"><button>Rozcestník hydrologie</button></Link>
    <Link to="/hydrologie/hydrologicky-cyklus/"><button className="inv">Hydrologický cyklus &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
